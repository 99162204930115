<div class="menu">
  <div class="mobile">
    <button
      pButton
      class="botao-burguer"
      type="button"
      (click)="alternarVisibilidade()"
    >
      <app-cached-img
        [src]="
          visivel
            ? 'assets/images/icones/icone-fechar-branco.svg'
            : 'assets/images/icones/icone-burguer.svg'
        "
      />
    </button>
  </div>
  <div class="desktop">
    <div class="desktop-itens">
      <ng-container *ngFor="let item of itensMenu">
        <button
          type="button"
          [disabled]="!item.habilitado"
          [pTooltip]="item.nome | translate"
          (click)="item.onClick()"
        >
          <app-cached-img [src]="item.icone" />
        </button>
      </ng-container>
      <button type="button" [pTooltip]="'sair' | translate" (click)="sair()">
        <app-cached-img src="assets/images/icones/icone-sair-vermelho.png" />
      </button>
      <div
        class="nome-usuario-desktop"
        translate
        [translateParams]="{ '0': nomeUsuario }"
      >
        inicio.ola
      </div>
    </div>
  </div>
</div>

<div class="menu-dialog mobile" [ngClass]="{ exibir: visivel }">
  <div class="nome-usuario">
    <app-iniciais-usuario [exibirNome]="true" />
  </div>
  <div class="itens-menu" *ngIf="itensMenu?.length">
    <ul>
      <li *ngFor="let item of itensMenu">
        <button
          type="button"
          [disabled]="!item.habilitado"
          (click)="item.onClick()"
        >
          <app-cached-img [src]="item.icone" />
          <span translate>
            {{ item.nome }}
          </span>
          <app-cached-img
            src="assets/images/icones/icone-seta-direita-branco.svg"
          />
        </button>
      </li>
    </ul>
  </div>
  <div class="itens-menu">
    <button pButton class="botao-sair" (click)="sair()">
      <app-cached-img src="assets/images/icones/icone-sair-vermelho.png" />
      <span translate> sair</span>
    </button>
  </div>
</div>
