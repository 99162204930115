export enum ClienteEnum {
  AMT = "amt",
  HMG1 = "hmg1",
  HMG2 = "hmg2",
  VILLARES = "villaresmetals",
  VALLOUREC = "vallourec",
  WEG = "weg",
  DESENV = "desenv",
  GERDAU = "gerdau",
  ALCOA = "alcoa",
  TERNIUM = "ternium",
  VALE = "vale",
  DEMO = "demo",
  SME = "sme",
  FOSPAR = "fospar",
}
