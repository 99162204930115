<app-card-opacity *ngIf="!habilitado">
  {{ "inicio.mensagemBloqueado" | translate }}
  <a (click)="testeProntidaoClick.emit()" translate> inicio.testeProntidao </a>
</app-card-opacity>

<div [ngClass]="{ blur: !habilitado }">
  <div class="container">
    <div class="cards-container">
      <div class="card-item" *ngFor="let k of niveisKeys">
        <app-inicio-card>
          <div class="card-content">
            <div>
              <app-cached-img
                class="nivel-img"
                [alt]="getNivel(k).titulo | translate"
                [src]="getNivel(k).icone"
              />
              <p class="nivel-disclaimer" translate>
                {{ getNivel(k).titulo }}
                <br />
                <span class="nivel-disclaimer__opaco" translate>
                  {{ getNivel(k).descricao }}
                  <em
                    *ngIf="getNivel(k).tooltip as tooltip"
                    class="pi pi-info-circle"
                    [pTooltip]="tooltip | translate"
                  ></em>
                </span>
              </p>
            </div>
            <p-knob
              [ngModel]="getNivel(k).valor"
              [readonly]="true"
              [size]="70"
              valueTemplate="{value}%"
            />
          </div>
        </app-inicio-card>
      </div>
    </div>

    <div class="feedback" *ngIf="fraseFeedback">
      <app-cached-img
        *ngIf="fraseFeedback == 'desvio.semDesvio'"
        src="assets/images/icones/check-circle.png"
      />
      <app-cached-img
        *ngIf="fraseFeedback != 'desvio.semDesvio'"
        src="assets/images/icones/alerta-amarelo.png"
      />
      <p [innerHTML]="fraseFeedback | translate"></p>
    </div>
  </div>
</div>
