import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { dispatchError } from "src/app/core/dispatchError";
import { ConfiguracaoTesteApiService } from "src/app/shared/api-services/configuracao-teste.api-service";
import { CardOpacityComponent } from "src/app/shared/components/card-opacity/card-opacity.component";
import { ResponseHistoricoClassifResultadoV2TO } from "src/app/shared/tos/response-historico-classificacao-resultado-v2.to";

type NiveisList = {
  [name: string]: NivelClassificacaoResultado;
};
interface NivelClassificacaoResultado {
  titulo: string;
  icone: string;
  descricao: string;
  valor: number;
  possuiDesvio: boolean;
  tooltip: string;
}

@Component({
  selector: "app-inicio-nivel-geral-prontidao",
  templateUrl: "./inicio-nivel-geral-prontidao.component.html",
  styleUrls: ["./inicio-nivel-geral-prontidao.component.scss"],
})
export class InicioNivelGeralProntidaoComponent implements OnInit {
  @Input() habilitado: boolean = true;
  @Output() testeProntidaoClick = new EventEmitter();

  fraseFeedback: string;
  loading: boolean = false;
  niveisKeys = [
    "vlPercAtencao",
    "vlPercTempoReacao",
    "vlPercConcentracao",
    "vlPercControleImpulsos",
  ];
  niveis: NiveisList = {
    vlPercAtencao: {
      titulo: "nivelProntidao.atencao",
      tooltip: "nivelProntidao.atencaoTooltip",
      descricao: "nivelProntidao.maiorMelhor",
      icone: "assets/images/inicio/historico-classificacao/atencao.svg",
      valor: 0,
      possuiDesvio: false,
    },
    vlPercTempoReacao: {
      titulo: "nivelProntidao.reacao",
      tooltip: "nivelProntidao.reacaoTooltip",
      descricao: "nivelProntidao.maiorMelhor",
      icone: "assets/images/inicio/historico-classificacao/reacao.svg",
      valor: 0,
      possuiDesvio: false,
    },
    vlPercConcentracao: {
      titulo: "nivelProntidao.cencentracao",
      tooltip: "nivelProntidao.cencentracaoTooltip",
      descricao: "nivelProntidao.maiorMelhor",
      icone: "assets/images/inicio/historico-classificacao/concentracao.svg",
      valor: 0,
      possuiDesvio: false,
    },
    vlPercControleImpulsos: {
      titulo: "nivelProntidao.cautela",
      tooltip: "nivelProntidao.cautelaTooltip",
      descricao: "nivelProntidao.maiorMelhor",
      icone: "assets/images/inicio/historico-classificacao/cautela.svg",
      valor: 0,
      possuiDesvio: false,
    },
  };

  constructor(private configuracaoService: ConfiguracaoTesteApiService) {}

  ngOnInit(): void {
    this.getHistoricoClassificacaoResultado();
  }

  getNivel(key: string) {
    return this.niveis[key];
  }

  getHistoricoClassificacaoResultado() {
    this.loading = true;
    this.configuracaoService.getHistoricoClassificacaoResultado().subscribe(
      (response) => {
        this.niveis = this.parseToNivelClassificaoResultadoList(response);
        this.loading = false;
        this.fraseFeedback = this.getFeedback();
      },
      (err) => {
        this.loading = false;
        dispatchError("Erro ao buscar Classificacao Resultado", err);
      }
    );
  }

  parseToNivelClassificaoResultadoList(
    response: ResponseHistoricoClassifResultadoV2TO
  ) {
    const keys = Object.keys(response);
    for (let k of keys) {
      this.niveis[k].valor = response[k] || 0;
      this.niveis[k].possuiDesvio = this.niveis[k].valor < 70;
    }
    return this.niveis;
  }

  getFeedback() {
    const hasDesvio = (keys: string[]) =>
      keys.every((k) => this.niveis[k].possuiDesvio);
    if (
      hasDesvio([
        "vlPercAtencao",
        "vlPercTempoReacao",
        "vlPercConcentracao",
        "vlPercControleImpulsos",
      ])
    ) {
      return "desvio.desvio1";
    } else if (
      hasDesvio([
        "vlPercTempoReacao",
        "vlPercConcentracao",
        "vlPercControleImpulsos",
      ])
    ) {
      return "desvio.desvio2";
    } else if (
      hasDesvio(["vlPercAtencao", "vlPercTempoReacao", "vlPercConcentracao"])
    ) {
      return "desvio.desvio3";
    } else if (
      hasDesvio([
        "vlPercAtencao",
        "vlPercControleImpulsos",
        "vlPercConcentracao",
      ])
    ) {
      return "desvio.desvio4";
    } else if (
      hasDesvio([
        "vlPercAtencao",
        "vlPercControleImpulsos",
        "vlPercTempoReacao",
      ])
    ) {
      return "desvio.desvio5";
    } else if (hasDesvio(["vlPercTempoReacao", "vlPercConcentracao"])) {
      return "desvio.desvio6";
    } else if (hasDesvio(["vlPercControleImpulsos", "vlPercConcentracao"])) {
      return "desvio.desvio7";
    } else if (hasDesvio(["vlPercControleImpulsos", "vlPercTempoReacao"])) {
      return "desvio.desvio8";
    } else if (hasDesvio(["vlPercAtencao", "vlPercConcentracao"])) {
      return "desvio.desvio9";
    } else if (hasDesvio(["vlPercAtencao", "vlPercTempoReacao"])) {
      return "desvio.desvio10";
    } else if (hasDesvio(["vlPercAtencao", "vlPercControleImpulsos"])) {
      return "desvio.desvio11";
    } else if (hasDesvio(["vlPercConcentracao"])) {
      return "desvio.desvio12";
    } else if (hasDesvio(["vlPercTempoReacao"])) {
      return "desvio.desvio13";
    } else if (hasDesvio(["vlPercControleImpulsos"])) {
      return "desvio.desvio14";
    } else if (hasDesvio(["vlPercAtencao"])) {
      return "desvio.desvio15";
    } else {
      return "desvio.semDesvio";
    }
  }
}
