import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { PerguntasComponent } from "./perguntas/perguntas.component";
import { PerguntasInicialComponent } from "./perguntasinicial/perguntasinicial.component";
import { TesteComponent } from "./teste/teste.component";
import { RedirectAuthComponent } from "./redirect-auth/redirect-auth.component";
import { ResultadoComponent } from "./resultado/resultado.component";
import { DicaComponent } from "./dica/dica.component";
import { ConfiguracaoComponent } from "./configuracao/configuracao.component";
import { QuestionarioComplementarComponent } from "./perguntas/questionario-complementar/questionario-complementar.component";
import { PrimeNgModule } from "../shared/primeng/primeng.module";
import { IntroducaoComponent } from "./introducao/introducao.component";
import { RouterModule } from "@angular/router";
import { PerguntasPsicologiaComponent } from "./perguntas/perguntas-psicologia/perguntas-psicologia.component";
import { PesquisaVacinacaoComponent } from "./perguntasinicial/pesquisa-vacinacao/pesquisa-vacinacao.component";
import { PerguntaInicalContentComponent } from "./perguntasinicial/pergunta-inical-content/pergunta-inical-content.component";
import { InicioComponent } from "./inicio/inicio.component";
import { FinalizarComponent } from "./finalizar/finalizar.component";
import { ProcessarTesteComponent } from "./processar-teste/processar-teste.component";
import { EmociogramaInicioComponent } from "./emociograma/emociograma-inicio.component";
import { EmociogramaTesteComponent } from "./emociograma/emociograma-teste.component";
import { EmociogramaCardsComponent } from "./emociograma/components/emociograma-cards/emociograma-cards.component";
import { BemEstarIlustradoComponent } from "./perguntas/bem-estar-ilustrado/bem-estar-ilustrado.component";
import { BemEstarTextoComponent } from "./perguntas/bem-estar-texto/bem-estar-texto.component";
import { FiltrarPerguntasPipe } from "./perguntasinicial/pesquisa-vacinacao/filtrar-perguntas.pipe";
import { DesempenhoComponent } from "./desempenho/desempenho.component";
import { AbordagensComponent } from "./abordagens/abordagens.component";
import { PerguntasCompInseguroComponent } from "./perguntas-comp-inseguro/perguntas-comp-inseguro.component";
import { PerguntasMatutinidadeComponent } from "./perguntas-matutinidade/perguntas-matutinidade.component";
import { InicioComunicadosComponent } from "./inicio/inicio-comunicados/inicio-comunicados.component";
import { InicioHistoricoResultadoComponent } from "./inicio/inicio-historico-resultado/inicio-historico-resultado.component";
import { InicioCardComponent } from "./inicio/inicio-card/inicio-card.component";
import { InicioMenuItemComponent } from "./inicio/inicio-menu-item/inicio-menu-item.component";
import { FormApoioProfissionalComponent } from "./perguntas/form-apoio-profissional/form-apoio-profissional.component";
import { TooltipModule } from "primeng/tooltip";
import { InicioAcessoRapidoComponent } from "./inicio/inicio-acesso-rapido/inicio-acesso-rapido.component";
import { InicioNivelGeralProntidaoComponent } from "./inicio/inicio-nivel-geral-prontidao/inicio-nivel-geral-prontidao.component";
import { CardOpacityComponent } from "../shared/components/card-opacity/card-opacity.component";
import { SegurancaPsicologicaComponent } from "./seguranca-psicologica/components/seguranca-psicologica/seguranca-psicologica.component";
import { ModalAdiarSegurancaPsicologicaComponent } from "./seguranca-psicologica/components/modal-adiar-seguranca-psicologica/modal-adiar-seguranca-psicologica.component";
import { PerguntasEpworthComponent } from "./perguntas-epworth/perguntas-epworth.component";
import { PerguntasSamPerelliComponent } from "./perguntas-sam-perelli/perguntas-sam-perelli.component";
import { PerguntasKarolinskaComponent } from "./perguntas-karolinska/perguntas-karolinska.component";
import { QRCodeModule } from "angularx-qrcode";
import { SegurancaPsicologicaInicioComponent } from "./seguranca-psicologica/seguranca-psicologica-inicio.component";
import { SegurancaPsicologicaTesteComponent } from "./seguranca-psicologica/seguranca-psicologica-teste.component";
import { PerguntasAvulsoClienteComponent } from "./perguntas-avulso-cliente/perguntas-avulso-cliente.component";

const components = [
  TesteComponent,
  PerguntasComponent,
  DicaComponent,
  RedirectAuthComponent,
  ConfiguracaoComponent,
  PerguntasInicialComponent,
  IntroducaoComponent,
  PesquisaVacinacaoComponent,
  PerguntasCompInseguroComponent,
  PerguntasSamPerelliComponent,
  PerguntasKarolinskaComponent,
  PerguntasAvulsoClienteComponent,
];

@NgModule({
  imports: [
    SharedModule,
    PrimeNgModule,
    RouterModule,
    TooltipModule,
    QRCodeModule,
    CardOpacityComponent,
  ],
  declarations: [
    components,
    ResultadoComponent,
    DicaComponent,
    QuestionarioComplementarComponent,
    PerguntasPsicologiaComponent,
    PerguntaInicalContentComponent,
    InicioComponent,
    FinalizarComponent,
    ProcessarTesteComponent,
    EmociogramaInicioComponent,
    EmociogramaTesteComponent,
    EmociogramaCardsComponent,
    BemEstarIlustradoComponent,
    BemEstarTextoComponent,
    FiltrarPerguntasPipe,
    DesempenhoComponent,
    InicioMenuItemComponent,
    InicioCardComponent,
    InicioHistoricoResultadoComponent,
    InicioComunicadosComponent,
    AbordagensComponent,
    PerguntasCompInseguroComponent,
    PerguntasMatutinidadeComponent,
    FormApoioProfissionalComponent,
    InicioAcessoRapidoComponent,
    InicioNivelGeralProntidaoComponent,
    SegurancaPsicologicaComponent,
    SegurancaPsicologicaInicioComponent,
    SegurancaPsicologicaTesteComponent,
    ModalAdiarSegurancaPsicologicaComponent,
    PerguntasEpworthComponent,
  ],
})
export class PagesModule {}
