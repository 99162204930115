<app-simulador-borda *ngIf="isSimulacao"></app-simulador-borda>
<router-outlet
  name="termoOutlet"
  (activate)="onTermoAtivate($event)"
></router-outlet>

<app-header></app-header>

<div
  class="conteudoDetalhe conteudoDetalheInstrucao p-md-12"
  [attr.aria-busy]="loading"
>
  <div class="conteudoCabecalho">
    <div class="tituloSaudacao">
      <div translate>
        introducao.textoSaudacao
        <span class="robotoBold">{{ nomeUsuario }}!</span>
      </div>
    </div>

    <div class="introducao">
      <div
        [innerHTML]="
          (isSimulacao
            ? 'introducao.textoIntroducaoSimulacao'
            : 'introducao.textoIntroducao'
          ) | translate
        "
      ></div>
      <br />
    </div>
  </div>
  <div class="p-grid">
    <div class="p-lg-5 orientacao">
      <h3 class="orientacao-titulo" translate>
        introducao.textoTituloOrientacao
      </h3>
      <div
        id="textoIntroducao"
        [innerHTML]="'introducao.textoOrientacao' | translate"
      ></div>
      <div class="imagem-instrucao-iniciar-teste" *ngIf="exibirImagemInstrucao">
        <app-cached-img
          [alt]="'introducao.altImagemInstrucao' | translate"
          className="img-instrucao"
          src="assets/images/teste/{{ nomeImagemInstrucao }}"
          *ngIf="loading"
        ></app-cached-img>
      </div>
    </div>

    <div class="p-sm-12 p-lg-7" id="figurasWrapper">
      <div class="p-grid">
        <div id="figuraCorreta" class="p-col-6">
          <div
            id="tituloFiguraCorreta"
            [innerHTML]="'introducao.figuraCorreta' | translate"
          ></div>
          <br />
          <div>
            <div class="containerImages bordaImagemCerta" *ngIf="loading">
              <div class="fundoImagem">
                <app-cached-img
                  [alt]="'introducao.altImagemCorreta' | translate"
                  src="assets/images/teste/introducao/{{ imagensCorretas[0] }}"
                  *ngIf="loading"
                ></app-cached-img>
              </div>
            </div>

            <div
              style="display: flex; justify-content: center"
              *ngIf="!loading"
            >
              <div class="loader" style="position: initial"></div>
            </div>
          </div>
        </div>

        <div id="figuraIncorreta" class="p-col-6">
          <div
            id="tituloFiguraIncorreta"
            [innerHTML]="'introducao.figuraIncorreta' | translate"
          ></div>
          <br />
          <div class="p-grid" *ngIf="loading" style="margin-top: 0">
            <ng-container
              *ngFor="let imagemIncorreta of imagensIncorretas; let i = index"
            >
              <div class="containerImages">
                <div
                  id="imgIncorreta"
                  class="p-col-6 fundoImagem"
                  style="padding: 0; margin-right: -19px"
                >
                  <app-cached-img
                    [alt]="'introducao.altImagemIncorreta' | translate"
                    src="assets/images/teste/introducao/{{ imagemIncorreta }}"
                  ></app-cached-img>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="p-grid" *ngIf="!loading">
            <div style="display: flex; justify-content: center; width: 100%">
              <div
                id="loaderIncorreto"
                class="loader"
                style="position: relative; top: 6px; left: 10px"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="botaoIntroducao"
      style="display: flex"
      style="margin: 0 auto; margin-bottom: 20px"
    >
      <button
        style="margin: 0 auto"
        [label]="'botaoIniciar' | translate"
        pButton
        class="botao"
        (click)="navegar()"
        [disabled]="!isTestePronto()"
      >
        <div class="loader" *ngIf="!isTestePronto()"></div>
      </button>
    </div>
  </div>
</div>
