import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ClienteEnum } from "src/app/shared/enums/Cliente.enum";
import { RespostaBuscaAtivaV2TO } from "src/app/shared/tos/request-resposta-busca-ativa.to";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-form-apoio-profissional",
  templateUrl: "./form-apoio-profissional.component.html",
  styleUrls: ["./form-apoio-profissional.component.scss"],
})
export class FormApoioProfissionalComponent {
  @Output() submitData = new EventEmitter<RespostaBuscaAtivaV2TO>();
  @Input() show: boolean;
  cliente: string = environment.cliente;

  submit() {
    this.submitData.emit();
    this.sair();
  }

  isGerdau() {
    return this.cliente == ClienteEnum.GERDAU;
  }

  sair() {
    this.show = false;
  }
}
