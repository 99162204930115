import { Component, Input } from "@angular/core";
import { ResponseNovidadeTO } from "../../tos/response-novidade.to";

@Component({
  selector: "app-comunicado-card",
  templateUrl: "./comunicado-card.component.html",
  styleUrls: ["./comunicado-card.component.scss"],
})
export class ComunicadoCardComponent {
  @Input() comunicado: ResponseNovidadeTO;
  exibirComunicado: boolean = false;

  exibirDetalhes() {
    this.exibirComunicado = true;
  }

  fecharDetalhes() {
    this.exibirComunicado = false;
  }
}
