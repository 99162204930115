import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { MenuOpcoesService } from "src/app/shared/services/menu-opcoes.service";
import { RoleService } from "src/app/shared/services/role.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ResponseValidarTesteInicial } from "src/app/shared/tos/response-validar-teste-inicial.to";

@Component({
  selector: "app-inicio-acesso-rapido",
  templateUrl: "./inicio-acesso-rapido.component.html",
  styleUrls: ["./inicio-acesso-rapido.component.scss"],
})
export class InicioAcessoRapidoComponent implements OnInit {
  @Output() alert = new EventEmitter();
  @Input() configuracao: ResponseValidarTesteInicial;
  exibirAcessoRapido = true;
  menuItens: any[] = [];

  constructor(
    private menuService: MenuOpcoesService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.menuItens = this.menuService.getItensAcessoRapido(this.configuracao);
    this.exibirAcessoRapido = this.menuItens.length > 0;
    this.sessionStorageService.setItem(
      SessionStorageKeys.POSSUI_VARIAS_ETAPAS,
      this.menuItens.length
    );
  }
}
