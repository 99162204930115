<app-card-opacity *ngIf="!habilitado" translate>
  {{ "inicio.mensagemBloqueado" | translate }}
  <a (click)="testeProntidaoClick.emit()" translate> inicio.testeProntidao </a>
</app-card-opacity>

<div [ngClass]="{ blur: !habilitado }">
  <h2 class="titulo" translate>avisos.titulo</h2>
  <p class="disclaimer" translate>inicio.comunicadosDescricao</p>
  <app-inicio-card *ngIf="isLoading || !novidades?.length">
    <div
      *ngIf="isLoading"
      class="loader p-m-auto"
      style="position: relative; left: auto; top: auto"
    ></div>
    <div
      *ngIf="!isLoading && !novidades?.length"
      class="p-text-center"
      translate
    >
      inicio.semComunicados
    </div>
  </app-inicio-card>

  <ng-container *ngIf="novidades?.length">
    <div class="comunicados-list">
      <app-comunicado-card
        *ngFor="let comunicado of novidades"
        [comunicado]="comunicado"
      />
    </div>
    <p class="texto-mais-novidades">
      <span translate> inicio.eMais </span> {{ qtdTotal }}
    </p>
    <button
      type="button"
      class="botao-ver-mais"
      (click)="goToComunicados()"
      translate
    >
      inicio.todosAvisos
    </button>
  </ng-container>
</div>
