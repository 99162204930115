import { Component, OnDestroy, OnInit } from "@angular/core";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { LogService } from "src/app/shared/services/log.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";

@Component({
  selector: "app-desempenho",
  templateUrl: "./desempenho.component.html",
  styleUrls: ["./desempenho.component.scss"],
})
export class DesempenhoComponent implements OnInit, OnDestroy {
  resultadoTesteTO: ResultadoTesteTO;
  corDesempenho: string;

  constructor(
    private sessionStorageService: SessionStorageService,
    private inactiveTimerService: InactiveTimerService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.logService.append("Acessou desempenho");
    this.resultadoTesteTO = this.sessionStorageService.getItem(
      SessionStorageKeys.RESULTADO
    );
    this.corDesempenho = this.resultadoTesteTO.cdRgbHexNivelResultado;
    this.inactiveTimerService.stop();
  }

  ngOnDestroy(): void {
    this.inactiveTimerService.stop();
  }
}
