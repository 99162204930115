<header class="header">
  <div class="logo-content">
    <app-cached-img class="logo" src="assets/images/logo.png" />
  </div>
  <div class="info-content">
    <app-iniciais-usuario class="mobile" />
    <app-menu *ngIf="exibirMenu" [configuracao]="configuracao" />
  </div>
</header>
<!-- <div class="header">
  <div class="conteudoHeader">
    <div class="p-grid">
      <div class="p-col-6 alinhar-vertical">
        <app-cached-img src="assets/images/logo.png"></app-cached-img>
      </div>
      <div
        class="p-col-6"
        style="color: #ffffff; size: 18px; text-align: right"
      >
        {{ nome }}
        <button
          class="botao-sair"
          *ngIf="exibirSair"
          translate
          (click)="sair()"
        >
          sair
        </button>
      </div>
    </div>
  </div>
</div> -->

<ng-container *ngIf="titulo || subtitulo">
  <div class="conteudoCabecalho">
    <h1
      *ngIf="titulo"
      class="tituloSaudacao"
      style="padding: 12px 0.5rem !important"
      id="header-titulo"
      translate
    >
      {{ titulo }}
    </h1>
    <p
      *ngIf="subtitulo"
      class="p-col-12 p-mx-auto subtitulo p-my-0"
      style="max-width: 960px"
      [innerHtml]="subtitulo"
      id="header-sub-titulo"
    ></p>
  </div>
  <div class="arrowClippath"></div>
</ng-container>
