<app-inicio-card>
  <div class="content" *ngIf="!isLoading; else loadingTemplate">
    <app-historico-resultado
      *ngIf="responseHistoricoEvolucao"
      [historico]="responseHistoricoEvolucao"
      [exibirTextoEvolucao]="false"
      class="small"
    />
  </div>
</app-inicio-card>

<ng-template #loadingTemplate>
  <div class="loading-content">
    <div class="loader"></div>
  </div>
</ng-template>
