<app-alert-bar />
<app-header
  [exibirSair]="true"
  [exibirMenu]="true"
  [configuracao]="configuracaoTeste"
/>

<div class="conteudoDetalhe">
  <div class="menu-row">
    <div
      *ngIf="
        !loadingConfig && configuracaoTeste?.flHabilitadoAvaliacao == 'SIM'
      "
      class="avaliar-prontidao-wrapper"
    >
      <app-inicio-card style="display: block; max-width: 265px">
        <app-cached-img
          class="img-avaliar-prontidao"
          src="assets/images/inicio/man-working.svg"
        />
        <p class="titulo" translate>inicio.testeProntidao</p>
        <button
          type="button"
          class="botao-inicio"
          (click)="acessarAvaliacao()"
          [disabled]="configuracaoTeste?.flPodeExecutarAvaliacaoAgora == 'NAO'"
          translate
        >
          inicio.fazerTeste
        </button>
        <small
          class="mensagem-impeditiva"
          *ngIf="configuracaoTeste?.flPodeExecutarAvaliacaoAgora == 'NAO'"
          [innerHtml]="configuracaoTeste?.dsMensagemImpeditivaAvaliacaoAgora"
        >
        </small>
      </app-inicio-card>
    </div>

    <div class="historico-resultado-wrapper">
      <h3 class="titulo" translate>inicio.historicoResultado</h3>
      <p class="descricao" translate>inicio.historicoResultadoDescricao</p>

      <app-inicio-nivel-geral-prontidao
        *ngIf="exibirNivelGeralProntidao"
        [ngClass]="{ hide: loadingConfig }"
        (testeProntidaoClick)="acessarAvaliacao()"
      />
    </div>

    <app-inicio-historico-resultado
      class="grafico-item"
      [isLoading]="loadingConfig"
    />

    <app-inicio-acesso-rapido
      *ngIf="configuracaoTeste"
      [ngClass]="{ hide: loadingConfig }"
      [configuracao]="configuracaoTeste"
    />

    <app-inicio-comunicados
      *ngIf="exibirComunicados"
      [ngClass]="{ hide: loadingConfig }"
      (testeProntidaoClick)="acessarAvaliacao()"
    />
  </div>
</div>
