import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { ResponseValidarTesteInicial } from "../../tos/response-validar-teste-inicial.to";

@Component({
  selector: "app-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() titulo?: string;
  @Input() subtitulo?: string;
  @Input() exibirSair: boolean = false;
  @Input() exibirMenu: boolean = false;
  @Input() configuracao: ResponseValidarTesteInicial;

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  sair() {
    this.authService.handleLogout();
  }
}
