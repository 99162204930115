<app-header></app-header>

<div class="conteudoDetalhe">
  <p class="texto-resultado-holder">
    <span class="texto-desempenho" translate> resultado.seuDesempenho </span>
    <span class="valor-desempenho" [ngStyle]="{ color: corDesempenho }">
      {{ resultadoTesteTO.vlDesempenhoAtual }}%</span
    >
  </p>

  <div
    class="progress-bar-container"
    [ngStyle]="{ 'border-color': corDesempenho }"
  >
    <div
      class="progress-bar"
      [style.width.%]="resultadoTesteTO.vlDesempenhoAtual"
      [ngStyle]="{ background: corDesempenho }"
    >
      <div class="container-percentual-e-indicador"></div>
    </div>
    <div
      class="resultado-anterior"
      [style.width.%]="resultadoTesteTO.vlDesempenhoAnterior"
    >
      <span class="texto-valor-anterior" translate
        >resultado.desempenhoAvaliacaoAnterior</span
      >
    </div>
  </div>

  <app-botoes-resultado></app-botoes-resultado>
</div>
