import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MenuOpcao } from "src/app/shared/model/menu-opcao.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { MenuOpcoesService } from "src/app/shared/services/menu-opcoes.service";
import { UsuarioDataService } from "src/app/shared/services/usuario-data.service";
import { ResponseValidarTesteInicial } from "src/app/shared/tos/response-validar-teste-inicial.to";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, OnChanges {
  @Input() configuracao: ResponseValidarTesteInicial;

  itensMenu: MenuOpcao[] = [];
  visivel: boolean = false;
  nomeUsuario: string;

  constructor(
    private menuService: MenuOpcoesService,
    private usuarioDataService: UsuarioDataService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.nomeUsuario = this.usuarioDataService.getName();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuracao.currentValue) {
      this.itensMenu = this.menuService.getItensAcessoRapido(this.configuracao);
    }
  }

  alternarVisibilidade() {
    this.visivel = !this.visivel;
    this.travarScroll(this.visivel);
  }

  travarScroll(open: boolean) {
    const body = document.getElementsByTagName("body")[0];
    const html = document.getElementsByTagName("html")[0];
    if (open) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = null;
      html.style.overflow = null;
    }
  }

  sair() {
    this.authService.handleLogout();
  }
}
