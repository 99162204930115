import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogNotificationComponent } from "./components/dialog-notification/dialog-notification.component";
import { HeaderComponent } from "./components/header/header.component";
import { TranslateModule } from "@ngx-translate/core";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { CachedImgComponent } from "./components/cached-img/cached-img.component";
import { TimerTxtComponent } from "./components/timer-txt/timer-txt.component";
import { SelectCardComponent } from "./components/select-card/select-card.component";
import { AlertBarComponent } from "./components/alert-bar/alert-bar.component";
import { QuestionScaleComponent } from "./components/question-scale/question-scale.component";
import { SimuladorBordaComponent } from "./components/simulador-borda/simulador-borda.component";
import { BotoesResultadoComponent } from "./components/botoes-resultado/botoes-resultado.component";
import { RouterModule } from "@angular/router";
import { QuestionarioGeralComponent } from "./components/questionario-geral/questionario-geral.component";
import { DropdownModule } from "primeng/dropdown";
import { HistoricoResultadoComponent } from "./components/historico-resultado/historico-resultado.component";
import { GraficoHistoricoResultadoComponent } from "./components/grafico-historico-resultado/grafico-historico-resultado.component";
import { ChartModule } from "angular-highcharts";
import { FocusTrapModule } from "primeng/focustrap";
import { BotaoConversaLiderComponent } from "./components/botao-conversa-lider/botao-conversa-lider.component";
import { PrimeNgModule } from "./primeng/primeng.module";
import { CheckboxModule } from "primeng/checkbox";
import { RadioButtonModule } from "primeng/radiobutton";
import { DialogModule } from "primeng/dialog";
import { StepperComponent } from "./components/stepper/stepper.component";
import { IniciaisUsuarioComponent } from "./components/header/iniciais-usuario/iniciais-usuario.component";
import { MenuComponent } from "./components/header/menu/menu.component";
import { ComunicadoCardComponent } from "./components/comunicado-card/comunicado-card.component";
import { TooltipModule } from "primeng/tooltip";

const modules = [CommonModule, FormsModule, ReactiveFormsModule, PrimeNgModule];

const components = [DialogNotificationComponent, HeaderComponent];

const directives = [];

@NgModule({
  declarations: [
    components,
    directives,
    CachedImgComponent,
    TimerTxtComponent,
    SelectCardComponent,
    AlertBarComponent,
    QuestionScaleComponent,
    SimuladorBordaComponent,
    BotoesResultadoComponent,
    GraficoHistoricoResultadoComponent,
    HistoricoResultadoComponent,
    QuestionarioGeralComponent,
    BotaoConversaLiderComponent,
    StepperComponent,
    IniciaisUsuarioComponent,
    MenuComponent,
    ComunicadoCardComponent,
  ],
  imports: [
    ...modules,
    TranslateModule,
    ConfirmDialogModule,
    RouterModule,
    ChartModule,
    DialogModule,
    FocusTrapModule,
    RadioButtonModule,
    CheckboxModule,
    DropdownModule,
    TooltipModule,
  ],
  exports: [
    ...modules,
    components,
    directives,
    TranslateModule,
    CachedImgComponent,
    TimerTxtComponent,
    SelectCardComponent,
    AlertBarComponent,
    QuestionScaleComponent,
    SimuladorBordaComponent,
    BotoesResultadoComponent,
    GraficoHistoricoResultadoComponent,
    HistoricoResultadoComponent,
    QuestionarioGeralComponent,
    BotaoConversaLiderComponent,
    StepperComponent,
    ComunicadoCardComponent,
  ],
})
export class SharedModule {}
