import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { TabelaAscii } from "src/app/shared/enums/tabela-ascii.enum";
import { AlvoTO } from "src/app/shared/tos/alvo.to";
import { PerguntaTO } from "src/app/shared/tos/pergunta.to";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";
import { AuthService } from "src/app/shared/services/auth.service";
import { RespostaPerguntaTO } from "src/app/shared/tos/resposta-pergunta.to";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { TesteService } from "src/app/shared/api-services/teste.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { DefaultPage } from "../default-page";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { BooleanEnum } from "src/app/shared/enums/boolean.enum";
import { NotificationService } from "src/app/shared/services/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { RetryService } from "src/app/shared/services/retry.service";
import { PerguntasPsicologiaComponent } from "./perguntas-psicologia/perguntas-psicologia.component";
import { LogService } from "src/app/shared/services/log.service";
import { wait } from "src/app/shared/utils/wait.util";
import { environment } from "src/environments/environment";
import { ClienteEnum } from "src/app/shared/enums/Cliente.enum";
import {
  PerguntasBemEstar,
  RespostasBemEstar,
} from "./bem-estar-component.interface";
import { Utils } from "src/app/shared/utils/utils";
import { TipoPeriodicidadePerguntaEnum } from "src/app/shared/enums/tipo-periodicidade-pergunta.enum";

@Component({
  selector: "app-perguntas",
  templateUrl: "./perguntas.component.html",
  styleUrls: ["./perguntas.component.scss"],
})
export class PerguntasComponent extends DefaultPage implements OnInit {
  @ViewChild(PerguntasPsicologiaComponent)
  perguntasPsicologiaComponent: PerguntasPsicologiaComponent;

  perguntasBemEstar: PerguntasBemEstar = {} as any;
  perguntasBemEstarIndex: number = 0;
  respostasBemEstar: RespostasBemEstar = {} as any;
  tiposPeriodicidade: TipoPeriodicidadePerguntaEnum[] = [];

  configuracao: ConfiguracaoTesteTO;
  private acoes: Array<AlvoTO>;
  private resultadoTesteTO: ResultadoTesteTO;
  waiting: boolean;
  tempoEsperaParaHabilitarBotao: number;
  private isTempoInatividade: boolean = false;
  checked: boolean = false;

  enviar: boolean = true;
  showQuestionario: boolean = false;
  showQuestionarioApoioProfissional: boolean = false;
  questionarioApoioProfissionalRespondido: boolean = false;
  questionarioComplementar: PerguntaTO[];
  questionarioComplementarRespondido: boolean = false;
  flExibeQuestionarioIlustrado: boolean;

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private testeService: TesteService,
    private readonly authService: AuthService,
    private inactiveTimerService: InactiveTimerService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private retryService: RetryService,
    private logService: LogService
  ) {
    super();
  }

  ngOnInit() {
    this.logService.append(`Acessou perguntas`);
    this.configuracao = this.sessionStorageService.getItem(
      SessionStorageKeys.CONFIGURACAO
    );

    if (this.configuracao.flExibeQuestionarioBemEstar === BooleanEnum.NAO) {
      this.goToNextStep(true);
      return;
    }
    this.acoes = this.sessionStorageService.getItem(SessionStorageKeys.ALVOS);

    this.perguntasBemEstar = Utils.groupBy(
      this.configuracao.perguntas,
      (item) => item.cdTipoPeriodicidade
    ) as any;
    this.tiposPeriodicidade = Object.keys(
      this.perguntasBemEstar
    ) as TipoPeriodicidadePerguntaEnum[];
    this.tiposPeriodicidade.sort((a) =>
      a == TipoPeriodicidadePerguntaEnum.DIARIA ? -1 : 1
    );

    this.tiposPeriodicidade.forEach((tipo) => {
      this.respostasBemEstar[tipo] = this.perguntasBemEstar[tipo].map(
        (pergunta) => ({
          id: pergunta.id,
          flMarcada: false,
        })
      );
    });

    this.loading = true;
    this.flExibeQuestionarioIlustrado =
      this.configuracao?.flExibeQuestionarioIlustrado == BooleanEnum.SIM;
    this.tempoEsperaParaHabilitarBotao = 2300;
    setTimeout(() => {
      this.loading = false;
    }, this.tempoEsperaParaHabilitarBotao);

    this.resetTempoUsuarioInativo();

    document.onkeydown = function (e) {
      //Bloqueio da tecla Ctrl
      if (e.ctrlKey) {
        return false;
      }
    };
  }

  getAllPerguntas() {
    const keys = Object.keys(this.perguntasBemEstar);
    return keys.reduce((prev, cur) => {
      return [...prev, ...this.perguntasBemEstar[cur]];
    }, []);
  }

  getAllRespostas() {
    const keys = Object.keys(this.respostasBemEstar);
    return keys.reduce((prev, cur) => {
      return [...prev, ...this.respostasBemEstar[cur]];
    }, []);
  }

  get isQuestionarioPeriodico() {
    return (
      this.tiposPeriodicidade[this.perguntasBemEstarIndex] ==
      TipoPeriodicidadePerguntaEnum.PERIODICA
    );
  }

  get textoHeader() {
    if (this.isQuestionarioPeriodico) {
      return this.translateService.instant("perguntas.textoPerguntasPeriodico");
    }
    const clientes = [ClienteEnum.ALCOA];
    const textoTraducao =
      clientes.includes(environment.cliente) &&
      this.configuracao?.idContrato == 1
        ? "perguntas.textoPerguntasAlcoa"
        : "perguntas.textoPerguntas";
    return this.translateService.instant(textoTraducao);
  }

  async handleUsuarioInativo() {
    this.isTempoInatividade = true;
    await this.concluirTeste(false);
  }

  private notificarQtdRespondidos(): Promise<number> {
    return new Promise((res, reject) => {
      const qtdRespostasMarcadas = this.getAllRespostas().filter(
        (r) => r.flMarcada
      )?.length;
      let message: string;
      if (qtdRespostasMarcadas == 0) {
        message = this.translateService.instant("perguntas.confirmarNenhuma");
      } else {
        message = this.translateService.instant(
          qtdRespostasMarcadas == 1
            ? "perguntas.confirmarVariasSingular"
            : "perguntas.confirmarVariasPlural"
        );
      }
      message = message.replace("{0}", String(qtdRespostasMarcadas));
      this.notificationService.notify({
        rejectVisible: true,
        acceptLabel: this.translateService.instant("sim"),
        message: message,
        handler: () => res(qtdRespostasMarcadas),
        handlerReject: () => reject(false),
      });
    });
  }

  resetTempoUsuarioInativo() {
    this.inactiveTimerService.start(
      () => this.handleUsuarioInativo(),
      undefined,
      false
    );
  }

  getPerguntasSelecionadasComQuestionarioComplementar() {
    if (!this.getAllRespostas()) return null;
    const respostasMarcadas = this.getAllRespostas().filter((r) => r.flMarcada);
    const perguntasSelecionadas = this.getAllPerguntas().filter((pergunta) => {
      return respostasMarcadas.find((r) => r.id === pergunta.id) !== undefined;
    });
    const perguntasComQuestionarioComplementar = perguntasSelecionadas.filter(
      (pergunta) => pergunta.itens && pergunta.itens.length
    );
    return perguntasComQuestionarioComplementar.length
      ? perguntasComQuestionarioComplementar
      : null;
  }

  juntarRespostasQuestionarioComplementar(respostasQuestionarioC: any) {
    Object.keys(respostasQuestionarioC).forEach((idPergunta) => {
      const respostaPergunta = this.getAllRespostas().find(
        (r) => r.id == Number(idPergunta)
      );
      respostaPergunta.idItemOpcoes = [];
      Object.keys(respostasQuestionarioC[idPergunta]).forEach((idItem) => {
        const isRespostasArray = Array.isArray(
          respostasQuestionarioC[idPergunta][idItem]
        );

        // Normaliza as respostas para todos em array
        const respontasNormalized = isRespostasArray
          ? respostasQuestionarioC[idPergunta][idItem]
          : [respostasQuestionarioC[idPergunta][idItem]];

        // Limpa valores nulos
        const respostasComplementares = respontasNormalized.filter(
          (r) => r != null && r != undefined
        );
        respostaPergunta.idItemOpcoes = [
          ...respostaPergunta.idItemOpcoes,
          ...respostasComplementares,
        ];
      });
    });
  }

  handleQuestionarioSuccess(respostasQuestionarioC: any) {
    this.juntarRespostasQuestionarioComplementar(respostasQuestionarioC);
    this.questionarioComplementarRespondido = true;
    this.showQuestionario = false;
    this.concluirTeste(true);
  }

  handleApoioProfisonalSuccess() {
    this.questionarioApoioProfissionalRespondido = true;
    this.concluirTeste(true);
  }

  isLast() {
    return this.perguntasBemEstarIndex == this.tiposPeriodicidade?.length - 1;
  }

  handleVoltar() {
    if (this.perguntasBemEstarIndex > 0) {
      this.perguntasBemEstarIndex--;
    }
  }

  handleConcluir() {
    if (this.isLast()) {
      this.concluirTeste(true);
    } else {
      this.perguntasBemEstarIndex++;
    }
  }

  async concluirTeste(flEnviaRetornoParam: boolean) {
    this.inactiveTimerService.stop();
    this.questionarioComplementar =
      this.getPerguntasSelecionadasComQuestionarioComplementar();
    if (!flEnviaRetornoParam) {
      this.goToNextStep(flEnviaRetornoParam);
      return;
    }
    if (
      !this.isTempoInatividade &&
      this.questionarioComplementar &&
      this.questionarioComplementarRespondido === false
    ) {
      this.showQuestionario = true;
      this.resetTempoUsuarioInativo();
      return;
    }
    try {
      if (this.questionarioApoioProfissionalRespondido == false) {
        const qtdRespondido: number = await this.notificarQtdRespondidos();
        await wait(200);
        if (this.shouldShowApoioProfissional(qtdRespondido)) {
          this.showQuestionarioApoioProfissional = true;
          this.resetTempoUsuarioInativo();
          return;
        }
      }
      this.goToNextStep(flEnviaRetornoParam);
    } catch (err) {
      console.log("Parar de exibir mensagens");
    }
  }

  private shouldShowApoioProfissional(qtdRespondido: number) {
    const clientePermitido =
      environment.cliente == ClienteEnum.DESENV ||
      environment.cliente == ClienteEnum.HMG2 ||
      environment.cliente == ClienteEnum.GERDAU ||
      environment.cliente == ClienteEnum.FOSPAR;
    return (
      qtdRespondido > 0 &&
      clientePermitido &&
      this.questionarioApoioProfissionalRespondido == false
    );
  }

  goToNextStep(flEnviaRetornoParam: boolean) {
    this.logService.append(`Ir para seguranca psicologica`);
    this.enviar = false;
    this.sessionStorageService.setItem(
      SessionStorageKeys.RESPOSTA_BEM_ESTAR,
      this.isTempoInatividade ? [] : this.getAllRespostas() || []
    );
    this.sessionStorageService.setItem(
      SessionStorageKeys.ENVIA_RETORNO_PARAM,
      flEnviaRetornoParam
    );
    this.routerService.toSegurancaPsicologica();
  }

  @HostListener("document:keypress", ["$event"])
  public onKey(event: KeyboardEvent) {
    if (this.showQuestionarioApoioProfissional) return;

    const tecladoNumerico = event.keyCode || event.which;

    switch (tecladoNumerico) {
      case TabelaAscii.numeral1: {
        this.atualizarRespostaQuestionario(0);
        break;
      }
      case TabelaAscii.numeral2: {
        this.atualizarRespostaQuestionario(1);
        break;
      }
      case TabelaAscii.numeral3: {
        this.atualizarRespostaQuestionario(2);
        break;
      }
      case TabelaAscii.numeral4: {
        this.atualizarRespostaQuestionario(3);
        break;
      }
      case TabelaAscii.numeral5: {
        this.atualizarRespostaQuestionario(4);
        break;
      }
      case TabelaAscii.numeral6: {
        this.atualizarRespostaQuestionario(5);
        break;
      }
      case TabelaAscii.numeral7: {
        this.atualizarRespostaQuestionario(6);
        break;
      }
      case TabelaAscii.numeral8: {
        this.atualizarRespostaQuestionario(7);
        break;
      }
      case TabelaAscii.numeral9: {
        this.atualizarRespostaQuestionario(8);
        break;
      }
      case TabelaAscii.numeral0: {
        this.atualizarRespostaQuestionario(9);
        break;
      }
      case TabelaAscii.teclaEnter: {
        if (this.enviar == true) {
          this.concluirTeste(true);
        }
        break;
      }
      case TabelaAscii.teclaEspaco: {
        if (this.enviar == true) {
          this.concluirTeste(true);
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  private atualizarRespostaQuestionario(posicao: number) {
    this.getAllRespostas()[posicao].flMarcada =
      !this.getAllRespostas()[posicao].flMarcada;
  }

  get textoOrientacao() {
    if (this.isQuestionarioPeriodico) {
      return "perguntas.textoOrientacaoPeriodico";
    }
    return "perguntas.textoOrientacao";
  }
}
