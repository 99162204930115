import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UsuarioDataService {
  nameFromApiObservable = new BehaviorSubject(null);
  private nameFromApi: string;

  constructor(private keycloakService: KeycloakService) {}

  getClientName(): string {
    return this.getInstance().tokenParsed["cliente.nome"];
  }

  saveNameFromApi(name: string) {
    this.nameFromApi = name;
    this.nameFromApiObservable.next(name);
  }

  getName(): string {
    const family_name = this.getInstance().tokenParsed["family_name"];
    const given_name = this.getInstance().tokenParsed["given_name"];
    const name = this.getInstance().tokenParsed["name"];
    const nameFromKeycloack = family_name == given_name ? given_name : name;
    return this.nameFromApi || nameFromKeycloack;
  }

  getInitials() {
    const name = this.getName();
    const nameSplit = name.split(" ");
    const firstName = nameSplit[0];
    const lastName = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : " ";
    return firstName[0].toUpperCase() + lastName[0]?.toUpperCase();
  }

  getLoginName() {
    return this.getInstance().tokenParsed.preferred_username;
  }

  getToken() {
    return this.getInstance().token;
  }

  getInstance() {
    return this.keycloakService.getKeycloakInstance();
  }
}
