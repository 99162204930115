import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NovidadesService } from "src/app/shared/api-services/novidades.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { ResponseNovidadeTO } from "src/app/shared/tos/response-novidade.to";

@Component({
  selector: "app-inicio-comunicados",
  templateUrl: "./inicio-comunicados.component.html",
  styleUrls: ["./inicio-comunicados.component.scss"],
})
export class InicioComunicadosComponent implements OnInit {
  @Input() habilitado: boolean = true;
  @Output() testeProntidaoClick = new EventEmitter();

  novidades: ResponseNovidadeTO[];
  qtdTotal: number;
  isLoading = false;

  constructor(
    private routerService: RouterService,
    private novidadesService: NovidadesService
  ) {}

  ngOnInit(): void {
    this.getNovidades();
  }

  goToComunicados() {
    this.routerService.toComunicados();
  }

  getNovidades() {
    this.isLoading = true;
    this.novidadesService.getNovidadesCompleto(1, 3).subscribe((r) => {
      this.qtdTotal = r.qtRegistros;
      this.novidades = r.dado;
      this.isLoading = false;
    });
  }
}
