import { Injectable } from "@angular/core";
import { LogService } from "./log.service";
import { RouterService } from "./router-service.service";
import { NotificationService } from "./notification.service";
import { TranslateService } from "@ngx-translate/core";
import { ResponseValidarTesteInicial } from "../tos/response-validar-teste-inicial.to";
import { RoleService } from "./role.service";
import { MenuOpcao } from "../model/menu-opcao.model";

@Injectable({
  providedIn: "root",
})
export class MenuOpcoesService {
  private menuItens: MenuOpcao[] = [
    {
      id: "DASHBOARD",
      nome: "inicio.dashboard",
      descricao: "inicio.descricaoDashboard",
      textoBotao: "inicio.gerenciarEquipe",
      img: "assets/images/inicio/dashboard.svg",
      icone: "assets/images/icones/icone-grupo-branco.svg",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para Dashboard");
        this.routeService.goToPortal();
      },
    },
    {
      id: "EAD",
      nome: "moduloEad",
      descricao: "inicio.descricaoModuleEad",
      textoBotao: "inicio.acessar",
      img: "assets/images/inicio/ead.svg",
      icone: "assets/images/icones/icone-tela-branco.svg",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para EAD");
        this.routeService.goToEAD();
      },
    },
    {
      id: "SIMULADOR",
      nome: "inicio.simularTesteProntidao",
      descricao: "inicio.descricaoSimularTesteProntidao",
      textoBotao: "inicio.praticarTeste",
      img: "assets/images/inicio/simulador.svg",
      icone: "assets/images/icones/icone-foguete-branco.svg",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para Simulador");
        this.routeService.toIntroducaoSimulacao();
      },
    },
    {
      id: "CHECKLIST",
      nome: "inicio.checklistCovid",
      descricao: "",
      textoBotao: "inicio.acessar",
      img: "/assets/images/inicio/checklist-covid.png",
      icone: "/assets/images/inicio/checklist-covid.png",
      habilitado: false,
      exibir: true,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para CHECKLIST");
        this.routeService.toPerguntasInicial();
      },
    },
    {
      id: "SAUDE_MENTAL",
      nome: "inicio.saudeMental",
      descricao: "",
      textoBotao: "inicio.acessar",
      img: "/assets/images/inicio/saude_mental.png",
      icone: "/assets/images/inicio/saude_mental.png",
      habilitado: false,
      exibir: true,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para SAUDE_MENTAL");
        this.routeService.toSaudeMental();
      },
    },
    {
      id: "EMOCIOGRAMA",
      nome: "inicio.emociograma",
      descricao: "",
      textoBotao: "inicio.acessar",
      img: "assets/images/emociograma/muito_feliz.png",
      icone: "assets/images/emociograma/muito_feliz.png",
      habilitado: false,
      exibir: true,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para EMOCIOGRAMA");
        this.acessarEmociograma();
      },
    },
    {
      id: "PERGUNTAS_MATUTINIDADE",
      nome: "inicio.perguntasMatutinidade",
      descricao: "inicio.descricaoPerguntasMatutinidade",
      textoBotao: "inicio.acessar",
      img: "assets/images/inicio/ciclo-circadiano.svg",
      icone: "assets/images/inicio/ciclo-circadiano-branco.svg",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para Perguntas Matutinidade");
        this.routeService.toPerguntasMatutinidade();
      },
    },
    {
      id: "SEGURANCA_PSICOLOGICA",
      nome: "inicio.segurancaPsicologica",
      descricao: "inicio.descricaoSegurancaPsicologica",
      textoBotao: "inicio.acessar",
      img: "assets/images/inicio/seguranca-psicologica.svg",
      icone: "assets/images/inicio/seguranca-psicologica-branco.svg",
      habilitado: false,
      exibir: false,
      msgAlerta: "",
      onClick: () => {
        this.logService.append("Clicou para Segurança Psicologica");
        this.routeService.toSegurancaPsicologicaInicial();
      },
    },
  ];

  constructor(
    private logService: LogService,
    private routeService: RouterService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private roleService: RoleService
  ) {}

  getItensAcessoRapido(configuracao: ResponseValidarTesteInicial): MenuOpcao[] {
    this.validarMenusExibidos(configuracao);
    this.validarMenusHabilitados(configuracao);
    this.definirMsgAlertas(configuracao);
    return this.menuItens.filter((menu) => menu.exibir);
  }

  private validarMenusExibidos(configuracao: ResponseValidarTesteInicial) {
    this.validarShowEad(configuracao);
    this.findById("PERGUNTAS_MATUTINIDADE").exibir =
      configuracao.perguntasMatutinidade?.length > 0;
    this.findById("CHECKLIST").exibir =
      configuracao.flHabilitadoPreTeste == "SIM";
    this.findById("SIMULADOR").exibir =
      configuracao.flHabilitadoAvaliacao == "SIM" &&
      configuracao.flExibeSimulador == "SIM";
    this.findById("SAUDE_MENTAL").exibir =
      configuracao.flHabilitadoSaudeMental == "SIM";
    this.findById("EMOCIOGRAMA").exibir =
      configuracao.flHabilitadoEmociograma == "SIM";
    this.findById("DASHBOARD").exibir = !this.roleService.isOnlyOperador();
    this.findById("SEGURANCA_PSICOLOGICA").exibir =
      configuracao.flHabilitadoApenasSegPsicologica == "SIM";
  }

  private validarMenusHabilitados(configuracao: ResponseValidarTesteInicial) {
    this.findById("PERGUNTAS_MATUTINIDADE").habilitado =
      configuracao.perguntasMatutinidade?.length > 0;
    this.findById("CHECKLIST").habilitado =
      configuracao.flExecutarPreTesteDisponivel == "SIM";
    this.findById("SAUDE_MENTAL").habilitado =
      configuracao.flHabilitadoSaudeMental == "SIM";
    this.findById("EMOCIOGRAMA").habilitado =
      configuracao.flExecutarEmociogramaDisponivel == "SIM";
    this.findById("SIMULADOR").habilitado =
      configuracao.flHabilitadoAvaliacao == "SIM" &&
      configuracao.flExibeSimulador == "SIM";
    this.findById("DASHBOARD").habilitado = !this.roleService.isOnlyOperador();
    this.findById("SEGURANCA_PSICOLOGICA").habilitado =
      configuracao.flHabilitadoApenasSegPsicologica == "SIM" &&
      !configuracao.msgNotificacao;
  }

  private acessarEmociograma() {
    const checklist = this.findById("CHECKLIST");
    if (checklist.habilitado && checklist.exibir) {
      this.notificationService.notifyWarning({
        message: this.translateService.instant("inicio.avisoEmociograma"),
      });
    } else {
      this.routeService.toEmociogramaInicial();
    }
  }

  findById(id) {
    return this.menuItens.find((menu) => menu.id == id);
  }

  private validarShowEad(configuracao: ResponseValidarTesteInicial) {
    const exibeEad = configuracao?.cdExibeEAD !== "DESATIVADO";
    this.findById("EAD").exibir = exibeEad;
    this.findById("EAD").habilitado = exibeEad;
  }

  private definirMsgAlertas(configuracao: ResponseValidarTesteInicial) {
    this.findById("SEGURANCA_PSICOLOGICA").msgAlerta =
      configuracao.msgNotificacao;
  }
}
