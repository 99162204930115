<div class="comunicado-item">
  <div
    class="comunicado-img-wrapper"
    [ngStyle]="{
      'background-image': 'url(' + comunicado.dsLinkImagem + ')'
    }"
  ></div>
  <div class="comunicado-content">
    <p class="comunicado-titulo" [innerHTML]="comunicado.dsTitulo"></p>
    <p class="comunicado-data">
      {{ comunicado.dhCriacao | date : "short" }}
    </p>
    <button
      pButton
      type="button"
      class="comunicado-button"
      translate
      (click)="exibirDetalhes()"
    >
      inicio.abrirComunicado
      <em class="pi pi-angle-right"></em>
    </button>
  </div>
</div>

<p-dialog
  [(visible)]="exibirComunicado"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{
    width: '90%',
    'max-width': '480px',
    'max-height': '98vh',
    overflow: 'auto'
  }"
  [closable]="true"
  [header]="comunicado.dsTitulo"
>
  <p class="comunicado-conteudo" [innerHTML]="comunicado.dsConteudoHtml"></p>
  <button
    pButton
    class="botao-fechar"
    type="button"
    (click)="fecharDetalhes()"
    translate
  >
    notificacao.ok
  </button>
  <p class="comunicado-informacoes">
    <span translate>inicio.modulo</span>: {{ comunicado.dsModulo }} <br />
    {{ comunicado.dhCriacao | date : "short" }}
  </p>
</p-dialog>
