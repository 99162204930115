import { Component, Input, OnInit } from "@angular/core";
import { UsuarioDataService } from "src/app/shared/services/usuario-data.service";

@Component({
  selector: "app-iniciais-usuario",
  templateUrl: "./iniciais-usuario.component.html",
  styleUrls: ["./iniciais-usuario.component.scss"],
})
export class IniciaisUsuarioComponent implements OnInit {
  @Input() exibirNome: boolean = false;
  iniciais: string;
  nomeUsuario: string;

  constructor(private usuarioDataService: UsuarioDataService) {}

  ngOnInit(): void {
    this.iniciais = this.usuarioDataService.getInitials();
    this.nomeUsuario = this.usuarioDataService.getName();
  }
}
